import { VariableSizeList } from 'react-window';
import { css, keyframes, styled } from 'styled-components';
import { Colors } from '../../../../play-core/styles/Colors';
import { Shadows } from '../../../../play-core/styles/Shadows';
import { zIndex } from '../../../../play-core/styles/z-index';
import { SearchInput } from '../SearchInput/SearchInput';
const dropdownTheme = theme => {
  if (theme.type === 'white') {
    return {
      containerBackground: Colors.SRF_ebebe5,
      borderColor: 'transparent',
      fontColor: Colors.SRF_4e4d47,
      scrollbarTrack: Colors.SRF_fafaf8,
      scrollbarThumb: Colors.SRF_4e4d47,
      separatorBackground: Colors.SRF_c91024 // verify color
    };
  }
  if (theme.type === 'outline') {
    return {
      containerBackground: Colors.GREY_454545,
      borderColor: Colors.GREY_969696,
      fontColor: Colors.GREY_d2d2d2,
      scrollbarTrack: Colors.GREY_969696,
      scrollbarThumb: Colors.GREY_2a2a2a,
      separatorBackground: Colors.GREY_515151
    };
  }
  return {
    containerBackground: Colors.GREY_3a3a3a,
    borderColor: 'transparent',
    fontColor: Colors.GREY_d2d2d2,
    scrollbarTrack: Colors.GREY_2a2a2a,
    scrollbarThumb: Colors.GREY_969696,
    separatorBackground: Colors.GREY_454545
  };
};
export const StyledDropdown = styled.div.withConfig({
  displayName: "Dropdownstyles__StyledDropdown",
  componentId: "sc-1v8jw2t-0"
})(["position:relative;min-width:", ";max-width:286px;"], ({
  expand
}) => expand ? '100%' : '144px');
export const DropdownSearchInput = styled(SearchInput).withConfig({
  displayName: "Dropdownstyles__DropdownSearchInput",
  componentId: "sc-1v8jw2t-1"
})(["margin:8px 16px;"]);
const slideDistance = 10;
const fadeIn = () => keyframes`
    0% {
      opacity: 0;
      transform: translateY(${`-${slideDistance}px`});
    }
    100% {
      opacity: 1;
    }
`;
export const DropdownContainer = styled.div.withConfig({
  displayName: "Dropdownstyles__DropdownContainer",
  componentId: "sc-1v8jw2t-2"
})(["", ";background-color:", ";color:", ";position:absolute;top:calc(100% + 5px);left:0;z-index:", ";padding:8px 0;width:100%;min-width:224px;border-radius:2px;animation:", " 200ms ease-in-out;animation-fill-mode:forwards;", ";"], Shadows.applyDouble(4), props => dropdownTheme(props.theme).containerBackground, props => dropdownTheme(props.theme).fontColor, zIndex.aboveHeader, fadeIn, ({
  theme
}) => dropdownTheme(theme).borderColor !== 'transparent' && `border: 1px solid ${dropdownTheme(theme).borderColor}`);
export const dropdownListHeight = 330;
const dropdownListStyle = css`
  margin: 0;
  padding: 0;
  max-height: ${dropdownListHeight}px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: ${props => dropdownTheme(props.theme).scrollbarTrack};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => dropdownTheme(props.theme).scrollbarThumb};
  }
`;
export const DropdownList = styled.ul.withConfig({
  displayName: "Dropdownstyles__DropdownList",
  componentId: "sc-1v8jw2t-3"
})(["", ""], dropdownListStyle);
export const VirtualDropdownList = styled(VariableSizeList).withConfig({
  shouldForwardProp: () => true
}).withConfig({
  displayName: "Dropdownstyles__VirtualDropdownList",
  componentId: "sc-1v8jw2t-4"
})(["", ""], dropdownListStyle);
export const Separator = styled.div.withConfig({
  displayName: "Dropdownstyles__Separator",
  componentId: "sc-1v8jw2t-5"
})(["height:1px;background-color:", ";margin:8px 0;"], props => dropdownTheme(props.theme).separatorBackground);